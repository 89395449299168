.loadmore__committee-container_article {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.loadmore__committee-container_article-image img {
  width: 100%;
  aspect-ratio: 1/1;
}

.loadmore__committee-container_article-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;
  height: 100%;
}

.loadmore__committee-container_article-content h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
}

.loadmore__committee-container_article-content h5 {
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 300;
  font-size: var(--font-sizeP);

  padding: 0.5em 0em;
}

.loadmore__committee-container_article-content p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  white-space: pre-wrap;
}

.loadmore__committee-container_article-content p:last-child {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-sizeP);

  padding-top: 0.5em;
}

@media screen and (max-width: 550px) {
  .loadmore__committee-container_article-content {
    padding: 0.2rem 0rem;
  }
}
