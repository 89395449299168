.loadmore__programme {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.loadmore__programme-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 108rem;
}

.loadmore__programme h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);
}

.loadmore__programme-section__table h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);
  padding-top: 2em;
  padding-bottom: 0.5em;
}

.loadmore__programme-section__table h5 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding-top: 2em;
}

.loadmore__programme-section__table table {
  border-collapse: collapse;
  margin: 0.5rem 0rem;
  width: 100%;
}

.loadmore__programme-section__table tr {
  background-color: rgb(30, 139, 255);
}

.loadmore__programme-section__table td {
  color: white;

  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding: 0.7em 1.2em;
  vertical-align: baseline;
}

.loadmore__programme-section__table .programme__weight {
  font-weight: 700;
  background-color: rgb(0, 50, 89);
}

.right {
  text-align: right;
  white-space: nowrap;
  width: 20% !important;
}

.cursive {
  background-color: rgb(171, 171, 171);
  font-style: italic;
}

.sticky-button {
  top: 40vh;
  position: sticky;
  z-index: 5;
  width: 100%;
  margin: 0 auto;
  max-width: 108rem;
}

.buttonNav {
  position: absolute;
  right: -5%;
  display: flex;
  flex-direction: column;
}

.programme__button-icon {
  font-size: var(--front-page-headings);
  margin: 0.2em;
  cursor: pointer;
}
.programme__button-icon:hover {
  filter: invert(50%);
}

@media screen and (max-width: 850px) {
  .right {
    width: 30% !important;
  }
  .buttonNav {
    right: -8%;
  }
}
