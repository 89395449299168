.registration {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.registration__section {
  margin: 0 auto;
  max-width: 120rem;
}

.registration__section h1 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--page-headings);
  padding-bottom: 1em;
}

.registration__section_container {
  display: flex;

  flex-direction: column;
}

.registration__section_container p {
  font-family: var(--font-familyp);
  font-style: italic;
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding-bottom: 2em;
}

.registration__section_container_table p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding-bottom: 0em;
  text-indent: 0em;
}

.registration__section_container_table h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  white-space: nowrap;
}
.registration__section_container_table h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
}
.registration__section_container_table h4 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--sub-headings);
}

.registration__section_container_table table {
  border-collapse: collapse;
}

.registration__background {
  background: #1d58a8;
  color: white;
  border: 2px solid #1d58a8;
}

.registration__section_container_table td {
  padding: 1em;

  width: 100rem;
}

.registration__border {
  border: 1px solid black;
}

.registration__section_container_table:last-child {
  display: none;
}

.registration__indent {
  padding-block: 2em;

  max-width: 80vw;
}

.registration__section p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeP);
  line-height: 2em;
}

.registration__btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.registration__buttonWrap-form {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  margin-top: 1rem;
  width: 250px;
}

.registration__buttonWrap-form a {
  font-family: var(--font-familyp);
  font-weight: 600;
  font-size: var(--font-sizeP);

  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.registration__buttonWrap-form a:hover {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  color: white;
}

@media screen and (max-width: 850px) {
  .registration__section_container p {
    text-indent: 0em;
  }

  .registration__section_container_table {
    display: none;
  }

  .registration__section_container_table:last-child {
    display: flex;
    flex-direction: column;
  }
  .registration__section_container_table h2 {
    white-space: normal;
  }
}

@media screen and (max-width: 330px) {
  .registration__section_container_table td {
    padding: 0.2em;
  }
}
