@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400;500;600;700&display=swap");

:root {
  --font-navbar: "Inter", sans-serif;
  --font-family: "Lexend", sans-serif;
  --font-familyp: "Noto Sans", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-navbar: linear-gradient(135deg, #1d58a8 0%, #039bee 100%);
  --color-bg: #040c18;
  --color-footer: linear-gradient(169.49deg, #1d58a8 0%, #039bee 171.2%);
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;

  --font-sizeP: clamp(0.75rem, 1vw, 1rem);
  --font-sizeNav: clamp(0.8rem, 1.5vw, 1.15rem);
  --front-page-headings: clamp(1.5rem, 3vw, 3rem);
  --sub-headings: clamp(0.95rem, 1.5vw, 1.5rem);
  --page-headings: clamp(1.9rem, 3.5vw, 4rem);

  --section-space: clamp(3rem, 6vw, 10rem);
}
