section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.spons__container img {
  width: 100%;
  object-fit: contain;
}

.spons__container h2 {
  font-size: var(--sub-headings);
  padding-top: 2em;
}
.scheduleh2 {
  padding-top: 0em;
  padding-bottom: 1em;
}
.spons__container p {
  font-size: var(--font-size-body);
  padding-bottom: 1em;
}
.spons__container a {
  font-size: var(--font-size-body);
  font-weight: 700;
  text-decoration: underline;
}

.spons__container td {
  padding-right: 1em;
  font-size: var(--font-size-body);
}
.spons__container td:first-of-type {
  text-align: right;
}
