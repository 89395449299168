.loadmore__congress {
  background-color: white;
}

.loadmore__congress-container {
  padding: 2rem 10rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loadmore__congress-container__text {
  display: flex;
  flex-direction: column;
  margin: 1.4rem;
}

.loadmore__congress-container__text h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 2rem;
}

.loadmore__congress-container__text p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.scaling {
  padding-bottom: 2rem;
  width: 392px;
}

.loadmore__congress-container__text button {
  width: 127px;
  border: 2px solid;
  border-radius: 100px;
  height: 30px;
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  background: rgba(0, 0, 0, 0);
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
  outline: none;
}

.loadmore__congress-container__amsterdam {
  background: rgba(30, 86, 166, 0.07);
  border-radius: 20px;
  width: 687px;
  height: 389px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.loadmore__congress-container__amsterdam h1 {
  text-align: center;
  background-image: url("../../assets/congress00.png");
  background-repeat: no-repeat;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  padding: 6.5rem 0rem;
  margin: 1rem 1.2rem;

  color: white;
}

.loadmore__congress-container__date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 1.5rem;
}

.loadmore__congress-container__date p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.loadmore__congress-container__event {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1129px;
  height: 389px;
  background: rgba(30, 86, 166, 0.07);
  border-radius: 20px;
}

.loadmore__congress-container__event h1 {
  text-align: center;
  background-image: url("../../assets/congress01.png");
  background-repeat: no-repeat;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  padding: 8rem 4.4rem;
  margin: 1.8rem 1rem 1rem 0rem;

  color: white;
}

.event__text {
  margin: 1.8rem 0rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event__text p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 520px;
}

.loadmore__congress-container__date h6 {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.event__text h6 {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.event__past {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
}

.event__past img {
  width: 510px;
}

.event__text2 {
  display: flex;
  justify-content: space-between;
  margin: 1.4rem 0rem 0rem 0rem;
}

.event__text2 h6 {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.event__text2 p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
