.precongress {
  background-color: white;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 120rem;
}

.precongress__container {
  display: flex;
  margin-block: clamp(2rem, 4vw, 7rem);
  flex-direction: column;
  width: 100%;
}

.precongress__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);
}

.precongress__container-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
}

.precongress__container-content_text {
  position: relative;
}
.precongress__container-content_text:first-child {
  display: none;
}

.precongress__container-content_text h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  margin-top: 3em;
}

.precongress__container-content_text p {
  font-family: var(--font-familyp);
  font-size: var(--font-sizeP);
  line-height: 2em;
}
.precongress__container-content_text a {
  text-decoration: underline;
  font-weight: 700;
}
.precongress__container-content_text a:hover {
  text-decoration: none;
}

.precongress__cursive {
  font-style: italic;
  font-weight: 600;
}

.precongress__weight {
  font-weight: 700;
}

.PrecongressDownloadLink {
  font-family: var(--font-familyp);
  font-size: var(--font-sizeP);
  font-style: italic;
  font-weight: 700;
}

@media screen and (max-width: 1080px) {
  .precongress__container-content {
    flex-direction: column;
  }

  .precongress__container-content_text {
    padding-bottom: 3rem;
  }
}
