section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}
.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}
.general_info h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--sub-headings);

  padding-bottom: 0.2em;
}
.general_info p,
.general_info li {
  font-family: var(--font-family);
  font-size: var(--font-size-body);
  padding-block: 0.2em;
}
