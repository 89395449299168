section {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.exhibitors_grid_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}
.exhibitors_grid_container a {
  cursor: pointer;
  padding: 1rem;
  border: 5px solid var(--color-text);
  border-radius: 20px;
  width: 100%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
}
.exhibitors_grid_container a:hover {
  border: 5px solid rgb(22, 151, 250);
}
.exhibitors_grid_container img {
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .exhibitors_grid_container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1000px) {
  .exhibitors_grid_container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .exhibitors_grid_container {
    grid-template-columns: repeat(1, 1fr);
  }
}
