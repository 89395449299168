* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: white;
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.padding__inline {
  padding-inline: 6rem;
}

.padding__block {
  padding-block: clamp(2rem, 4vw, 7rem);
}

.section__margin {
  margin: 4rem 6rem;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .padding__inline {
    padding-inline: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .padding__inline {
    padding-inline: 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

@media screen and (max-width: 330px) {
  .section__padding {
    padding: 4rem 0.5rem;
  }

  .padding__inline {
    padding-inline: 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}
