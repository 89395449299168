.popup_container {
  position: fixed;
  z-index: 500;
  width: 400px;
  background: rgb(245, 245, 245);
  bottom: 2rem;
  right: 2rem;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.popup_container button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0;
  background: rgba(255, 255, 255, 0.8);
  font-size: var(--font-size-body);
  z-index: 5;
  cursor: pointer;
  border-radius: 50px;
  aspect-ratio: 1/1;
  width: 2em;
}
.popup_container button:hover {
  background: rgba(255, 255, 255, 1);
}

.popup_container p {
  font-size: var(--font-size-body);
  font-family: var(--font-familyp);
  padding: 1em;
  text-align: center;
  padding-top: 3em;
  padding-bottom: 0;
}
.popup_container img {
  width: 100%;
  object-fit: contain;
}
.popup_container h4 {
  font-family: var(--font-family);
  font-size: var(--font-size-body);
  background: white;
  margin: 1em;
  padding: 0.5em;
  text-align: center;
}
.popup_container h4:hover {
  background: var(--color-footer);
  color: white;
}

@media screen and (max-width: 600px) {
  .popup_container {
    width: 80%;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    margin: 0 auto;
  }
}
