.sidebarLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  list-style: none;
  height: 60px;
  text-decoration: none;
  color: rgb(211, 211, 211);
  text-align: right;
  cursor: pointer;
  transition: 150ms;
}

.sidebarLink div {
  display: flex;
  flex-direction: row;
}

.sidebarLink:hover {
  color: white;
}

.sidebarLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  font-weight: 500;
  font-family: var(--font-navbar);
  font-size: var(--font-sizeNav);
}

.openSubmenu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: var(--font-sizeNav);
  scale: 1.3;
}

.dropdownLink {
  color: rgb(211, 211, 211);
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--font-sizeNav);
  cursor: pointer;
  transition: 150ms;
}

.dropdownLink:hover {
  color: white;
}

.activeSidebarLink {
  color: white;
}
