.loadmore__accomodation-container {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(0.5rem, 1.5vw, 3rem);

  margin-bottom: 2rem;
}

.accomodation__section-container_text h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);

  padding-bottom: 0.2em;
}
.accomodation__section-container_text p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  font-style: italic;
}
.accomodation__section-container_text h3 {
  font-family: var(--font-familyp);
  font-weight: 800;
  font-size: var(--font-sizeP);
  padding-bottom: 1.5em;
}

@media screen and (max-width: 1300px) {
  .loadmore__accomodation-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .loadmore__accomodation-container {
    gap: 5rem;
  }
}

@media screen and (max-width: 900px) {
  .loadmore__accomodation-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .loadmore__accomodation-container {
    gap: 0.5rem;
  }
}
