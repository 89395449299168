.sponsors {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.sponsors__section {
  margin: 0 auto;
  max-width: 120rem;
}

.sponsors__section h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}
.sponsors__section h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);

  padding-top: 2.5em;
  padding-bottom: 1em;
}

.sponsors__section_features {
  padding-top: 4rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.sponsors__section_features_text h5 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding-top: 1.5em;
  padding-bottom: 0.2em;
}
.sponsors__section_features_text h8 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--font-sizeP);
}
.sponsors__list_items {
  padding-inline: 1.5rem;
}
.sponsors__italic {
  font-style: italic;
  padding-top: 3em;
  padding-bottom: 1em;
}

.sponsors__list_container {
  display: flex;
}
.sponsors__exhibition ul,
.sponsors__section_features_text ol,
.sponsors__section_features_text ul {
  padding-left: 1.5rem;
}
.sponsors__exhibition li,
.sponsors__section_features_text li {
  padding-bottom: 0.5em;
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
}
.sponsors__list_items li {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding-bottom: 0em;
}

.sponsors__section_features_image {
  overflow: hidden;
  width: max(70%, 70rem);
  position: relative;
}

.sponsors__section_features_image img {
  height: 100%;
  object-fit: contain;
}

.sponsors__section_features h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);
  padding-top: 0em;
  padding-bottom: 1em;
}

.sponsors__section p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
}
.secretary__contact address,
.secretary__contact p {
  font-style: normal;
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
}
.secretary__contact address {
  padding-top: 1em;
}
.secretary__contact a {
  cursor: pointer;
  padding: 1em;
}
.secretary__contact a:hover {
  color: #1d58a8;
}

.ethicalMed {
  font-weight: 700;
  text-decoration: underline;
}

.ethicalMed:hover {
  text-decoration: none;
}

.sponsors__section_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 4rem;
}

.sponsors__section_container_benefits {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 30rem;
}
.sponsors__section_container_text {
  background: rgba(30, 86, 166, 0.07);
  border-radius: 0px 0px 10px 10px;
}

.sponsors__section_container_benefits h5 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--sub-headings);
  text-align: center;
  border-radius: 50px 50px 0px 0px;
  color: white;
  white-space: nowrap;
  padding-inline: 4em;
}
.sponsors__section_container_benefits h5:last-child {
  border-radius: 0px 0px 50px 50px;
  color: transparent;
}

.sponsors__section_container_text p {
  margin-inline: 1em;
  padding-block: 1em;
  border-bottom: 2px solid white;
}
.sponsors__section_container_text p:last-child {
  border-bottom: none;
}

.platinum {
  background: linear-gradient(91.24deg, #9e9eff 0%, #6466bb 100%);
}
.gold {
  background: linear-gradient(91.24deg, #ffe279 0%, #cd8b5b 100%);
}
.silver {
  background: linear-gradient(91.24deg, #dcdcdc 0%, #b2b3c4 100%);
}
.bronze {
  background: linear-gradient(91.24deg, #f7e1c0 0%, #b89371 100%);
}

.sponsors__buttonWrap-form {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  margin-top: 1rem;
  width: 50%;
}

.sponsors__buttonWrap-form a {
  font-family: var(--font-familyp);
  font-weight: 300;
  font-size: var(--font-sizeP);

  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.sponsors__buttonWrap-form a:hover {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  color: white;
}

@media screen and (max-width: 1080px) {
  .sponsors__section_features {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
  }
  .sponsors__section_features_image {
    overflow: unset;
    width: 100%;
  }

  .sponsors__section_features_image img {
    width: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 700px) {
  .sponsors__buttonWrap-form {
    width: 100%;
  }
}
