section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.posters__container {
  margin: 0 auto;
  max-width: 120rem;
}

.posters__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.posters__container td {
  font-size: var(--font-size-body);
  padding: 1em;
  vertical-align: baseline;
}

.posters_color {
  background-color: aliceblue;
}
