section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.spons__container embed {
  width: 100%;
  height: 70vh;
}

.final_mobile_message {
  display: none;
}

@media screen and (max-width: 650px) {
  .final_mobile_message {
    display: flex;
    flex-direction: column;
  }
}
