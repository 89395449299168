.sidebarIcon {
  display: none;
  font-size: var(--front-page-headings);
  cursor: pointer;
}

.centerNav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transform-origin: center;
  transition-duration: 400ms;
}

.sidebarNav {
  position: absolute;
  z-index: 8;
  background: rgb(29, 88, 168);
  width: max(150vw, 150vh);
  height: max(150vw, 150vh);
  transform: scale(0);
  border-radius: 50%;
  transform-origin: center;
  transition-duration: 400ms;
}

.icon {
  top: 0;
  right: 0;
  position: absolute;
  z-index: 8;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  padding-block: clamp(0.5rem, 1vw, 1rem);
  transition-duration: 300ms;
}

.activeSidebar {
  display: none;
  transform: scale(1.5);
}

.activeCenterNav {
  transform: scale(1);
}

.sidebarWrap {
  position: absolute;
  z-index: 8;
  top: 100%;
  left: 25%;
  opacity: 0;
  width: 50%;
  height: 90vh;
  overflow-y: scroll;
  display: none;
  scale: 0;
  flex-direction: column;
  transition-duration: 300ms;
}

.setActiveSide {
  opacity: 1;
  scale: 1;
}

@media screen and (max-width: 1550px) {
  .sidebarIcon,
  .centerNav,
  .activeSidebar,
  .sidebarWrap {
    display: flex;
  }
}

@media screen and (max-width: 550px) {
  .icon {
    padding: 0.6rem;
    padding-right: 1rem;
  }

  .sidebarWrap {
    width: 80%;
    left: 10%;
  }
}
