@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400;500;600;700&display=swap);
.loadmore__congress {
  background-color: white;
}

.loadmore__congress-container {
  padding: 2rem 10rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loadmore__congress-container__text {
  display: flex;
  flex-direction: column;
  margin: 1.4rem;
}

.loadmore__congress-container__text h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 2rem;
}

.loadmore__congress-container__text p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.scaling {
  padding-bottom: 2rem;
  width: 392px;
}

.loadmore__congress-container__text button {
  width: 127px;
  border: 2px solid;
  border-radius: 100px;
  height: 30px;
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  background: rgba(0, 0, 0, 0);
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
  outline: none;
}

.loadmore__congress-container__amsterdam {
  background: rgba(30, 86, 166, 0.07);
  border-radius: 20px;
  width: 687px;
  height: 389px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.loadmore__congress-container__amsterdam h1 {
  text-align: center;
  background-image: url(/static/media/congress00.a732fb6f.png);
  background-repeat: no-repeat;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  padding: 6.5rem 0rem;
  margin: 1rem 1.2rem;

  color: white;
}

.loadmore__congress-container__date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 1.5rem;
}

.loadmore__congress-container__date p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.loadmore__congress-container__event {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1129px;
  height: 389px;
  background: rgba(30, 86, 166, 0.07);
  border-radius: 20px;
}

.loadmore__congress-container__event h1 {
  text-align: center;
  background-image: url(/static/media/congress01.e56585fd.png);
  background-repeat: no-repeat;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  padding: 8rem 4.4rem;
  margin: 1.8rem 1rem 1rem 0rem;

  color: white;
}

.event__text {
  margin: 1.8rem 0rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event__text p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 520px;
}

.loadmore__congress-container__date h6 {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.event__text h6 {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.event__past {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
}

.event__past img {
  width: 510px;
}

.event__text2 {
  display: flex;
  justify-content: space-between;
  margin: 1.4rem 0rem 0rem 0rem;
}

.event__text2 h6 {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.event__text2 p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.loadmore__committee-container_article {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.loadmore__committee-container_article-image img {
  width: 100%;
  aspect-ratio: 1/1;
}

.loadmore__committee-container_article-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;
  height: 100%;
}

.loadmore__committee-container_article-content h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
}

.loadmore__committee-container_article-content h5 {
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 300;
  font-size: var(--font-sizeP);

  padding: 0.5em 0em;
}

.loadmore__committee-container_article-content p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  white-space: pre-wrap;
}

.loadmore__committee-container_article-content p:last-child {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-sizeP);

  padding-top: 0.5em;
}

@media screen and (max-width: 550px) {
  .loadmore__committee-container_article-content {
    padding: 0.2rem 0rem;
  }
}

.loadmore__accomodation-container_article {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.loadmore__accomodation-container_article-image img {
  width: 100%;
  aspect-ratio: 4/3;
}

.loadmore__accomodation-container_article-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;
  height: 100%;
}

.loadmore__accomodation-container_article-content h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding-bottom: 0em;
}

.loadmore__accomodation-container_article-content h5 {
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 300;
  font-size: var(--font-sizeP);

  padding: 0.5em 0em;
}

.loadmore__accomodation-container_article-content p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  line-height: 24px;
}

.booking__container {
  padding-top: 1rem;
}

.loadmore__accomodation-weight p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-sizeP);
  line-height: 24px;
}

@media screen and (max-width: 550px) {
  .loadmore__accomodation-container_article-content {
    padding: 0.2rem 0rem;
  }
}

.sidebarIcon {
  display: none;
  font-size: var(--front-page-headings);
  cursor: pointer;
}

.centerNav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition-duration: 400ms;
}

.sidebarNav {
  position: absolute;
  z-index: 8;
  background: rgb(29, 88, 168);
  width: max(150vw, 150vh);
  height: max(150vw, 150vh);
  -webkit-transform: scale(0);
          transform: scale(0);
  border-radius: 50%;
  -webkit-transform-origin: center;
          transform-origin: center;
  transition-duration: 400ms;
}

.icon {
  top: 0;
  right: 0;
  position: absolute;
  z-index: 8;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  padding-block: clamp(0.5rem, 1vw, 1rem);
  transition-duration: 300ms;
}

.activeSidebar {
  display: none;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.activeCenterNav {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.sidebarWrap {
  position: absolute;
  z-index: 8;
  top: 100%;
  left: 25%;
  opacity: 0;
  width: 50%;
  height: 90vh;
  overflow-y: scroll;
  display: none;
  scale: 0;
  flex-direction: column;
  transition-duration: 300ms;
}

.setActiveSide {
  opacity: 1;
  scale: 1;
}

@media screen and (max-width: 1550px) {
  .sidebarIcon,
  .centerNav,
  .activeSidebar,
  .sidebarWrap {
    display: flex;
  }
}

@media screen and (max-width: 550px) {
  .icon {
    padding: 0.6rem;
    padding-right: 1rem;
  }

  .sidebarWrap {
    width: 80%;
    left: 10%;
  }
}

.sidebarLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  list-style: none;
  height: 60px;
  text-decoration: none;
  color: rgb(211, 211, 211);
  text-align: right;
  cursor: pointer;
  transition: 150ms;
}

.sidebarLink div {
  display: flex;
  flex-direction: row;
}

.sidebarLink:hover {
  color: white;
}

.sidebarLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  font-weight: 500;
  font-family: var(--font-navbar);
  font-size: var(--font-sizeNav);
}

.openSubmenu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: var(--font-sizeNav);
  scale: 1.3;
}

.dropdownLink {
  color: rgb(211, 211, 211);
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--font-sizeNav);
  cursor: pointer;
  transition: 150ms;
}

.dropdownLink:hover {
  color: white;
}

.activeSidebarLink {
  color: white;
}

.position__navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
}

.loadmore__navbar {
  --background: rgba(0, 0, 0, 0);
  --text: white;
  --text-inverse: black;
  transition: 200ms ease-in;
  background: var(--background);
  width: 100%;
  display: flex;
  justify-content: center;
}

.loadmore__navbar:hover {
  background: rgb(29, 88, 168);
}

.testClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 130rem;
  padding-block: clamp(0.5rem, 1vw, 1rem);
}

.loadmore__navbar-links {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.loadmore__navbar-links_logo {
  z-index: 10;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loadmore__navbar-links_logo h1 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sub-headings);
  line-height: 30px;
  color: var(--text);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  white-space: nowrap;
}

.loadmore__navbar-links_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuLine {
  padding-inline: 0.5rem;
  white-space: nowrap;
  padding-block: 0.5em;
}

.menuLine div {
  font-family: var(--font-navbar);
  color: var(--text);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeNav);
  line-height: 24px;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.menuLine div:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  top: 26px;
  bottom: 0;
  left: 0;
  background-color: var(--text);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.menuLine div:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.absoluteDrop {
  display: flex;
  justify-content: center;
  position: relative;
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 40px;
  background-color: white;
  position: absolute;
  width: 100%;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.dropdownA {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: top;
          transform-origin: top;
}

.dropdown-link {
  display: block;
  font-family: var(--font-navbar);
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeP);

  padding: 1rem;
  cursor: pointer;
}

.dropdown-link:hover {
  background: #5cabff;
}

.nav-scrolled {
  --text: white;
  --text-inverse: white;
  --background: rgb(29, 88, 168);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.sidebarMenuIcon {
  color: var(--text);
}

.nav__sub {
  margin: 0em;
  position: absolute;
  bottom: -2px;
}

.showText h1 {
  transition-delay: 0.3s;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: left;
          transform-origin: left;
}

@media screen and (max-width: 1550px) {
  .loadmore__navbar-links_container {
    display: none;
  }
}

.page {
  min-height: 100vh;
}

.app {
  overflow: clip;
  max-width: 100vw;
}

.popup_container {
  position: fixed;
  z-index: 500;
  width: 400px;
  background: rgb(245, 245, 245);
  bottom: 2rem;
  right: 2rem;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.popup_container button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0;
  background: rgba(255, 255, 255, 0.8);
  font-size: var(--font-size-body);
  z-index: 5;
  cursor: pointer;
  border-radius: 50px;
  aspect-ratio: 1/1;
  width: 2em;
}
.popup_container button:hover {
  background: rgba(255, 255, 255, 1);
}

.popup_container p {
  font-size: var(--font-size-body);
  font-family: var(--font-familyp);
  padding: 1em;
  text-align: center;
  padding-top: 3em;
  padding-bottom: 0;
}
.popup_container img {
  width: 100%;
  object-fit: contain;
}
.popup_container h4 {
  font-family: var(--font-family);
  font-size: var(--font-size-body);
  background: white;
  margin: 1em;
  padding: 0.5em;
  text-align: center;
}
.popup_container h4:hover {
  background: var(--color-footer);
  color: white;
}

@media screen and (max-width: 600px) {
  .popup_container {
    width: 80%;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    margin: 0 auto;
  }
}

.loadmore__accomodation-container {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: clamp(0.5rem, 1.5vw, 3rem);
  gap: clamp(0.5rem, 1.5vw, 3rem);

  margin-bottom: 2rem;
}

.accomodation__section-container_text h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);

  padding-bottom: 0.2em;
}
.accomodation__section-container_text p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  font-style: italic;
}
.accomodation__section-container_text h3 {
  font-family: var(--font-familyp);
  font-weight: 800;
  font-size: var(--font-sizeP);
  padding-bottom: 1.5em;
}

@media screen and (max-width: 1300px) {
  .loadmore__accomodation-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .loadmore__accomodation-container {
    grid-gap: 5rem;
    gap: 5rem;
  }
}

@media screen and (max-width: 900px) {
  .loadmore__accomodation-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .loadmore__accomodation-container {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
}

.precongress {
  background-color: white;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 120rem;
}

.precongress__container {
  display: flex;
  margin-block: clamp(2rem, 4vw, 7rem);
  flex-direction: column;
  width: 100%;
}

.precongress__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);
}

.precongress__container-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
  width: 100%;
}

.precongress__container-content_text {
  position: relative;
}
.precongress__container-content_text:first-child {
  display: none;
}

.precongress__container-content_text h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  margin-top: 3em;
}

.precongress__container-content_text p {
  font-family: var(--font-familyp);
  font-size: var(--font-sizeP);
  line-height: 2em;
}
.precongress__container-content_text a {
  text-decoration: underline;
  font-weight: 700;
}
.precongress__container-content_text a:hover {
  text-decoration: none;
}

.precongress__cursive {
  font-style: italic;
  font-weight: 600;
}

.precongress__weight {
  font-weight: 700;
}

.PrecongressDownloadLink {
  font-family: var(--font-familyp);
  font-size: var(--font-sizeP);
  font-style: italic;
  font-weight: 700;
}

@media screen and (max-width: 1080px) {
  .precongress__container-content {
    flex-direction: column;
  }

  .precongress__container-content_text {
    padding-bottom: 3rem;
  }
}

.loadmore__footer {
  background: var(--color-footer);
  color: white;
}

.footer__maxWidth {
  margin: 0 auto;
  max-width: 130rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loadmore__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100%;
  text-align: left;
}

.loadmore__footer-heading {
  width: 65%;
}

.loadmore__footer-heading h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sub-headings);
}

.loadmore__footer-links div {
  margin-top: 1rem;
  margin-right: 2rem;
}

.loadmore__footer-links__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loadmore__footer-links__logo img {
  width: clamp(3rem, 8vw, 5rem);
  object-fit: contain;
  margin: 1rem;
}

.loadmore__footer-links__logo h1 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--front-page-headings);
}

.loadmore__footer-links__div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.loadmore__footer-links__div h4 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-sizeP);
  line-height: 22px;
}
.loadmore__footer-links__div a,
.loadmore__footer-links__div p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-sizeP);
}

.loadmore__footer-links__div a {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .footer__maxWidth {
    flex-direction: column;
  }
}

.venue {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.venue__section {
  margin: 0 auto;
  max-width: 120rem;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.venue__section h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 0.5em;
}

.venue__section-container {
  display: flex;
  flex-direction: row;

  grid-gap: 1rem;

  gap: 1rem;
}

.imageSize1 {
  height: max(100rem, 100%);
}
.imageSize2 {
  width: max(200rem, 190%);
}
.imageSize3 {
  width: max(100rem, 155%);
}

.imageWrap {
  overflow: hidden;
  border-radius: 2%;
}

.imageWrap img {
  object-fit: contain;
  width: 100%;
}

.venue__section-container_text {
  display: flex;
  flex-direction: column;
}

.venue__section-container_text h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);

  padding-bottom: 0.8em;
}
.venue__section-container_text h3,
.venue__section-container_text h4 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);

  padding-bottom: 0.8em;
}
.venue__section-container_text h4 {
  padding-top: 1.5em;
}

.venue__section-container_text p,
.venue__section-container_text li {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
}

.venue__section-container_text ul {
  padding: 1rem;
}

@media screen and (max-width: 1080px) {
  .imageSize1,
  .imageSize2,
  .imageSize3 {
    width: 100%;
  }

  .imageWrap img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
  .venue__section-container {
    flex-direction: column;
  }
}

.social {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.social__section {
  margin: 0 auto;
  max-width: 120rem;
}
.social__section img {
  width: 100%;
}

.social__section h1 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--page-headings);
}

.social__section h4 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--sub-headings);
  padding-top: 3em;
  padding-bottom: 1em;
}

.social__section p {
  font-family: var(--font-familyp);

  font-weight: 400;
  font-size: var(--font-sizeP);
}

.loadmore__home {
  background: white;

  color: black;
}

.loadmore__home-container {
  position: relative;
}
.home_container_wrapper {
  overflow: hidden;
  width: 100%;
  max-height: 100vh;
  position: relative;
}

.home_container_wrapper img {
  width: 100%;
  object-fit: contain;
}

.maxWidth {
  margin: 0 auto;
  max-width: 120rem;
}

.loadmore__home-container_logo {
  position: absolute;
  bottom: -2rem;
  display: flex;
  z-index: 4;
}

.loadmore__home-container_logo img {
  width: clamp(5rem, 10vw, 10.75rem);
  object-fit: contain;
}

.loadmore__home-container_logo-text {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  color: white;
  transition: 0.5s;
}

.loadmore__home-container_logo-text a {
  text-decoration: underline;
  padding: 1em;
}

.loadmore__home-container_logo-text:hover {
  background: rgba(0, 0, 0, 0.6);
}
.loadmore__home-container_logo-text p {
  font-family: var(--font-familyp);
  font-weight: 700;
  font-size: var(--font-sizeP);
}

.loadmore__home-container_logo-text h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--front-page-headings);
}

.loadmore__home-container_logo-text h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);

  padding-bottom: 0.5em;
}

.loadmore__home-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 120rem;
}

.loadmore__home-content_first {
  display: flex;
  flex-direction: row;
}

.loadmore__home-content_second {
  padding-top: 8rem;
  display: none;
}

.loadmore__home-content_first h3,
.loadmore__home-content_second h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);

  padding-bottom: 2rem;
}

.loadmore__home-content_third-text_container h3 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--sub-headings);

  padding-left: 1rem;
  margin-left: 7rem;
}

.loadmore__home-content_first-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loadmore__home-content_second-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loadmore__home-content_first-text p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  line-height: 24px;
  padding-right: 1rem;
  padding-bottom: 1rem;
}
.loadmore__home-content_third-text_container-white p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding: 1rem;
  padding-top: 0rem;
  margin-left: 7rem;
}

.loadmore__home-content_first-text_image {
  width: 270%;
  overflow: hidden;

  border-radius: 2%;
}

.loadmore__home-content_first-text_image img {
  height: 100%;
  object-fit: contain;
}

.loadmore__home-content_second-text_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem;
  margin-left: 0rem;
  height: 100%;
  width: 100%;
}

.loadmore__home-content_second-text_container img {
  width: 100%;
  height: 100%;
}

.picture__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
}

.picture__text h6 {
  font-family: var(--font-familyp);
  font-weight: 600;
  font-size: var(--font-sizeP);
}

.picture__text p {
  font-family: var(--font-familyp);
  font-weight: 300;
  font-size: var(--font-sizeP);
}

.loadmore__home-content_third {
  display: flex;
  justify-content: space-between;

  padding-top: var(--section-space);
}

.loadmore__home-content_third-text {
  position: relative;
  width: 45%;
  height: 100%;
}

.loadmore__home-content_third-text img {
  width: 200px;
  object-fit: contain;
  position: absolute;
  margin-top: 0.65rem;
}

.loadmore__home-content_third-text_container {
  border-radius: 10px;
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  padding: clamp(0.2rem, 0.5vw, 0.4rem);
  margin-left: 4rem;
}

.loadmore__home-content_third-text_container-white {
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
}

.see-more {
  width: 30%;
  margin: 2rem;
  margin-bottom: 4.5rem;
  border-radius: 10px;
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.see-more-inner {
  border-radius: 6px;
  background: white;
  width: 94%;
  height: 97%;
  display: flex;
  align-items: center;
}

.see-more-inner p {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--font-sizeNav);
  line-height: 30px;
  margin-left: 1rem;
  z-index: 1;
  white-space: nowrap;
  display: none;
}

.icon-home {
  font-size: var(--front-page-headings);
  z-index: 1;
  display: none;
}

.fade-home {
  position: absolute;
  left: 40%;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
}

@media screen and (max-width: 1080px) {
  .loadmore__home-content_first {
    display: flex;
    flex-direction: column;
  }
  .loadmore__home-content_first-text_image {
    width: 100%;
    overflow: unset;
  }
  .loadmore__home-content_first-text_image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .loadmore__home-content_third-text {
    position: relative;
    width: 48%;
    height: 100%;
    padding-top: 1rem;
  }

  .loadmore__home-content_third-text img {
    width: clamp(10rem, 17.5vw, 12rem);
    margin-top: 0rem;
    left: 1.2rem;
  }

  .loadmore__home-content_third-text_container h3 {
    margin-top: clamp(4.5rem, 9vw, 10rem);
    margin-left: 0rem;
    padding-left: 0.6rem;
  }
  .loadmore__home-content_third-text_container-white p {
    margin-left: 0rem;
    padding-left: 0.6rem;
  }
  .loadmore__home-content_third-text_container {
    margin-top: 5rem;
    margin-left: 0rem;
  }
  .loadmore__home-content_third-text_container-white {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .loadmore__home-content_third {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .loadmore__home-content_third-text {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
  }
  .loadmore__home-container_logo {
    position: absolute;
    bottom: 50%;
    display: flex;
    z-index: 4;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}

@media screen and (max-width: 550px) {
  .home_container_wrapper img {
    height: 100vh;
    object-fit: cover;
  }
}

.loadmore__programme {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.loadmore__programme-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 108rem;
}

.loadmore__programme h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);
}

.loadmore__programme-section__table h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);
  padding-top: 2em;
  padding-bottom: 0.5em;
}

.loadmore__programme-section__table h5 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding-top: 2em;
}

.loadmore__programme-section__table table {
  border-collapse: collapse;
  margin: 0.5rem 0rem;
  width: 100%;
}

.loadmore__programme-section__table tr {
  background-color: rgb(30, 139, 255);
}

.loadmore__programme-section__table td {
  color: white;

  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding: 0.7em 1.2em;
  vertical-align: baseline;
}

.loadmore__programme-section__table .programme__weight {
  font-weight: 700;
  background-color: rgb(0, 50, 89);
}

.right {
  text-align: right;
  white-space: nowrap;
  width: 20% !important;
}

.cursive {
  background-color: rgb(171, 171, 171);
  font-style: italic;
}

.sticky-button {
  top: 40vh;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  width: 100%;
  margin: 0 auto;
  max-width: 108rem;
}

.buttonNav {
  position: absolute;
  right: -5%;
  display: flex;
  flex-direction: column;
}

.programme__button-icon {
  font-size: var(--front-page-headings);
  margin: 0.2em;
  cursor: pointer;
}
.programme__button-icon:hover {
  -webkit-filter: invert(50%);
          filter: invert(50%);
}

@media screen and (max-width: 850px) {
  .right {
    width: 30% !important;
  }
  .buttonNav {
    right: -8%;
  }
}

.committee__maxWidth {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.loadmore__committee {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 120rem;
}

.loadmore__committee-heading {
  width: 100%;
  text-align: left;
}

.loadmore__committee-heading h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 2em;
}

.loadmore__committee-heading h3 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--sub-headings);
  padding-bottom: 2em;
}

.loadmore__committee-container {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: clamp(0.5rem, 1.5vw, 3rem);
  gap: clamp(0.5rem, 1.5vw, 3rem);

  margin-bottom: 2rem;
}

.loadmore__committee-padding {
  padding: 5rem 0rem;
}

@media screen and (max-width: 1500px) {
  .loadmore__committee-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1080px) {
  .loadmore__committee-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .loadmore__committee-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .loadmore__committee-container {
    grid-gap: 5rem;
    gap: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .loadmore__committee-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .loadmore__committee-container {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
}

.abstract {
  display: flex;
  justify-content: center;
  margin-block: clamp(2rem, 4vw, 7rem);
}

.abstract__section {
  position: relative;
  margin: 0 auto;
  max-width: 120rem;

  width: 100%;
}

.abstract__section h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.abstract__section h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);
  padding-block: 0.5em;
}
.abstract__section h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--sub-headings);
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.abstract__section p {
  font-family: var(--font-familyp);
  font-style: italic;
  font-weight: 400;
  font-size: var(--font-sizeP);
}

.abstract__section-text_wrap h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding-block: 0.5em;
}

.abstract__section-text_wrap ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;

  padding-left: 1rem;
}

.abstract__section ul {
  padding-left: 1.5rem;
}

.abstract__section li,
.abstract__section-text_wrap p,
.abstract__section-text_wrap li {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeP);
}

.abstract__section-text {
  display: flex;
  flex-direction: column;
}

.abstract__section-text_form {
  position: relative;

  padding-right: 1rem;
  margin-top: 2rem;
  width: 70%;
}

.abstract__section-text_form img {
  aspect-ratio: 1/1;
  width: 100%;
}

.abstract__section-text_wrap {
  padding-bottom: 1rem;
}
.abstract__section-text_wrap1 {
  padding-bottom: 1rem;
  margin-top: 2rem;
}

.abstract__section-text:last-child {
  flex-direction: column-reverse;
}

.abstract__fade {
  position: absolute;
  width: 100%;
  height: 40%;
  bottom: 0rem;

  background: linear-gradient(0deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
}

.abstract__buttonWrap {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  border-radius: 50px;
  padding: 11px 2px;
}

.abstract__buttonWrap-container {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 10rem;
  width: min(20rem, 80%);
}

.abstract__buttonWrap-form {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  margin-top: 1rem;
}

.abstract__buttonWrap-form a {
  font-family: var(--font-familyp);
  font-weight: 300;
  font-size: var(--font-sizeP);
  white-space: nowrap;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.abstract__buttonWrap-form a:hover {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  color: white;
}

@media screen and (max-width: 850px) {
  .abstract__buttonWrap-form a {
    padding: 5px 10px;
  }
  .abstract__section-text_form {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .abstract__buttonWrap-container {
    bottom: 4rem;
  }
}

.registration {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.registration__section {
  margin: 0 auto;
  max-width: 120rem;
}

.registration__section h1 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--page-headings);
  padding-bottom: 1em;
}

.registration__section_container {
  display: flex;

  flex-direction: column;
}

.registration__section_container p {
  font-family: var(--font-familyp);
  font-style: italic;
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding-bottom: 2em;
}

.registration__section_container_table p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding-bottom: 0em;
  text-indent: 0em;
}

.registration__section_container_table h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  white-space: nowrap;
}
.registration__section_container_table h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
}
.registration__section_container_table h4 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--sub-headings);
}

.registration__section_container_table table {
  border-collapse: collapse;
}

.registration__background {
  background: #1d58a8;
  color: white;
  border: 2px solid #1d58a8;
}

.registration__section_container_table td {
  padding: 1em;

  width: 100rem;
}

.registration__border {
  border: 1px solid black;
}

.registration__section_container_table:last-child {
  display: none;
}

.registration__indent {
  padding-block: 2em;

  max-width: 80vw;
}

.registration__section p {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeP);
  line-height: 2em;
}

.registration__btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.registration__buttonWrap-form {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  margin-top: 1rem;
  width: 250px;
}

.registration__buttonWrap-form a {
  font-family: var(--font-familyp);
  font-weight: 600;
  font-size: var(--font-sizeP);

  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.registration__buttonWrap-form a:hover {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  color: white;
}

@media screen and (max-width: 850px) {
  .registration__section_container p {
    text-indent: 0em;
  }

  .registration__section_container_table {
    display: none;
  }

  .registration__section_container_table:last-child {
    display: flex;
    flex-direction: column;
  }
  .registration__section_container_table h2 {
    white-space: normal;
  }
}

@media screen and (max-width: 330px) {
  .registration__section_container_table td {
    padding: 0.2em;
  }
}

.sponsors {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.sponsors__section {
  margin: 0 auto;
  max-width: 120rem;
}

.sponsors__section h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}
.sponsors__section h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);

  padding-top: 2.5em;
  padding-bottom: 1em;
}

.sponsors__section_features {
  padding-top: 4rem;
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
}

.sponsors__section_features_text h5 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding-top: 1.5em;
  padding-bottom: 0.2em;
}
.sponsors__section_features_text h8 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--font-sizeP);
}
.sponsors__list_items {
  padding-inline: 1.5rem;
}
.sponsors__italic {
  font-style: italic;
  padding-top: 3em;
  padding-bottom: 1em;
}

.sponsors__list_container {
  display: flex;
}
.sponsors__exhibition ul,
.sponsors__section_features_text ol,
.sponsors__section_features_text ul {
  padding-left: 1.5rem;
}
.sponsors__exhibition li,
.sponsors__section_features_text li {
  padding-bottom: 0.5em;
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
}
.sponsors__list_items li {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding-bottom: 0em;
}

.sponsors__section_features_image {
  overflow: hidden;
  width: max(70%, 70rem);
  position: relative;
}

.sponsors__section_features_image img {
  height: 100%;
  object-fit: contain;
}

.sponsors__section_features h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);
  padding-top: 0em;
  padding-bottom: 1em;
}

.sponsors__section p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
}
.secretary__contact address,
.secretary__contact p {
  font-style: normal;
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
}
.secretary__contact address {
  padding-top: 1em;
}
.secretary__contact a {
  cursor: pointer;
  padding: 1em;
}
.secretary__contact a:hover {
  color: #1d58a8;
}

.ethicalMed {
  font-weight: 700;
  text-decoration: underline;
}

.ethicalMed:hover {
  text-decoration: none;
}

.sponsors__section_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
  padding-top: 4rem;
}

.sponsors__section_container_benefits {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 30rem;
}
.sponsors__section_container_text {
  background: rgba(30, 86, 166, 0.07);
  border-radius: 0px 0px 10px 10px;
}

.sponsors__section_container_benefits h5 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--sub-headings);
  text-align: center;
  border-radius: 50px 50px 0px 0px;
  color: white;
  white-space: nowrap;
  padding-inline: 4em;
}
.sponsors__section_container_benefits h5:last-child {
  border-radius: 0px 0px 50px 50px;
  color: transparent;
}

.sponsors__section_container_text p {
  margin-inline: 1em;
  padding-block: 1em;
  border-bottom: 2px solid white;
}
.sponsors__section_container_text p:last-child {
  border-bottom: none;
}

.platinum {
  background: linear-gradient(91.24deg, #9e9eff 0%, #6466bb 100%);
}
.gold {
  background: linear-gradient(91.24deg, #ffe279 0%, #cd8b5b 100%);
}
.silver {
  background: linear-gradient(91.24deg, #dcdcdc 0%, #b2b3c4 100%);
}
.bronze {
  background: linear-gradient(91.24deg, #f7e1c0 0%, #b89371 100%);
}

.sponsors__buttonWrap-form {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  margin-top: 1rem;
  width: 50%;
}

.sponsors__buttonWrap-form a {
  font-family: var(--font-familyp);
  font-weight: 300;
  font-size: var(--font-sizeP);

  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.sponsors__buttonWrap-form a:hover {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  color: white;
}

@media screen and (max-width: 1080px) {
  .sponsors__section_features {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
  }
  .sponsors__section_features_image {
    overflow: unset;
    width: 100%;
  }

  .sponsors__section_features_image img {
    width: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 700px) {
  .sponsors__buttonWrap-form {
    width: 100%;
  }
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.spons_mini_container {
  margin: 0 auto;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spons_mini_container h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding: 1em;
  border-radius: 30px;
}

.platinum_grid_container,
.gold_grid_container,
.bronze_grid_container,
.additional_grid_container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 5rem;
  border: 0.2rem solid rgb(245, 245, 245);
  border-radius: 30px;
  place-items: center;
}
.platinum_grid_container h3,
.gold_grid_container h3,
.bronze_grid_container h3,
.additional_grid_container h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--font-sizeP);
  padding-bottom: 2em;
}
.platinum_grid_container a,
.gold_grid_container a,
.bronze_grid_container a,
.additional_grid_container a {
  cursor: pointer;
  padding: 1rem;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.platinum_grid_container,
.platinum_grid_container a {
  width: 100%;
}
.gold_grid_container,
.gold_grid_container a {
  width: 90%;
}
.bronze_grid_container,
.bronze_grid_container a {
  width: 80%;
}
.additional_grid_container,
.additional_grid_container div {
  width: 70%;
  display: grid;
  place-items: center;
}

.platinum_grid_container img,
.gold_grid_container img,
.bronze_grid_container img,
.additional_grid_container img {
  width: 100%;
}

.platinum {
  background: linear-gradient(91.24deg, #9e9eff 0%, #6466bb 100%);
  width: 100%;
}
.gold {
  background: linear-gradient(91.24deg, #ffe279 0%, #cd8b5b 100%);
  width: 90%;
}
.bronze {
  background: linear-gradient(91.24deg, #f7e1c0 0%, #b89371 100%);
  width: 80%;
}
.additional {
  background: linear-gradient(91.24deg, #b5dbfd 0%, #6bb7ed 100%);
  width: 70%;
}

.spons_bigger img {
  width: 115%;
}

@media screen and (max-width: 800px) {
  .platinum_grid_container,
  .gold_grid_container,
  .bronze_grid_container,
  .additional_grid_container {
    grid-template-columns: repeat(1, 1fr);
  }
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.exhibitors_grid_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
}
.exhibitors_grid_container a {
  cursor: pointer;
  padding: 1rem;
  border: 5px solid var(--color-text);
  border-radius: 20px;
  width: 100%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
}
.exhibitors_grid_container a:hover {
  border: 5px solid rgb(22, 151, 250);
}
.exhibitors_grid_container img {
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .exhibitors_grid_container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1000px) {
  .exhibitors_grid_container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .exhibitors_grid_container {
    grid-template-columns: repeat(1, 1fr);
  }
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
  font-family: var(--font-family);
}

.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.grid__section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5rem;
  gap: 5rem;
}

.title_bio p {
  white-space: pre-wrap;
  font-size: var(--font-sizeP);
  line-height: 1.5em;
}

.speaker_image_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.img_overflow_container {
  width: min(20vw, 12rem);
  aspect-ratio: 3/4;
  overflow: hidden;
  float: left;
  margin-bottom: 1rem;
  margin-right: 1rem;
  display: grid;
  place-items: center;
}
.img_overflow_container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.title_bio_container {
  background-color: aliceblue;
  width: 100%;
  padding-inline: 1rem;
  margin-bottom: 1rem;
}
.title_bio_container h3 {
  color: rgb(50, 129, 255);
  font-size: var(--sub-headings);
}
.title_bio_container p {
  padding-inline: 0rem;
  white-space: pre-wrap;
}

.speakers__weight {
  color: rgb(50, 129, 255);
}

.open_abstract h4 {
  padding-block: 1.5em;
  font-size: var(--font-sizeNav);
}
.open_abstract p {
  font-size: var(--font-sizeP);
  line-height: 1.5em;
  white-space: pre-wrap;
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.spons__container img {
  width: 100%;
  object-fit: contain;
}
.spons__container h2 {
  font-size: var(--sub-headings);
  padding-top: 2em;
}
.spons__container p {
  font-size: var(--font-size-body);
  padding-bottom: 1em;
}
.spons__container a {
  font-size: var(--font-size-body);
  font-weight: 700;
  text-decoration: underline;
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.spons__container img {
  width: 100%;
  object-fit: contain;
}

.spons__container h2 {
  font-size: var(--sub-headings);
  padding-top: 2em;
}
.scheduleh2 {
  padding-top: 0em;
  padding-bottom: 1em;
}
.spons__container p {
  font-size: var(--font-size-body);
  padding-bottom: 1em;
}
.spons__container a {
  font-size: var(--font-size-body);
  font-weight: 700;
  text-decoration: underline;
}

.spons__container td {
  padding-right: 1em;
  font-size: var(--font-size-body);
}
.spons__container td:first-of-type {
  text-align: right;
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.posters__container {
  margin: 0 auto;
  max-width: 120rem;
}

.posters__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.posters__container td {
  font-size: var(--font-size-body);
  padding: 1em;
  vertical-align: baseline;
}

.posters_color {
  background-color: aliceblue;
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}
.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}
.general_info h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--sub-headings);

  padding-bottom: 0.2em;
}
.general_info p,
.general_info li {
  font-family: var(--font-family);
  font-size: var(--font-size-body);
  padding-block: 0.2em;
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.assembly__container {
  margin: 0 auto;
  max-width: 120rem;
}

.assembly__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);
}

.assembly img {
  width: 100%;
}
.assembly_img1 {
  display: none;
}

@media screen and (max-width: 900px) {
  .assembly_img {
    display: none;
  }
  .assembly_img1 {
    display: flex;
  }
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.spons__container embed {
  width: 100%;
  height: 70vh;
}

.final_mobile_message {
  display: none;
}

@media screen and (max-width: 650px) {
  .final_mobile_message {
    display: flex;
    flex-direction: column;
  }
}

section {
  margin-block: clamp(2rem, 4vw, 7rem);
  font-family: var(--font-family);
}
.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.speakinfo_h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--sub-headings);

  padding-bottom: 0.2em;
}

.speakinfo_weight {
  font-weight: 700;
  font-style: italic;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: white;
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.padding__inline {
  padding-inline: 6rem;
}

.padding__block {
  padding-block: clamp(2rem, 4vw, 7rem);
}

.section__margin {
  margin: 4rem 6rem;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .padding__inline {
    padding-inline: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .padding__inline {
    padding-inline: 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

@media screen and (max-width: 330px) {
  .section__padding {
    padding: 4rem 0.5rem;
  }

  .padding__inline {
    padding-inline: 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

:root {
  --font-navbar: "Inter", sans-serif;
  --font-family: "Lexend", sans-serif;
  --font-familyp: "Noto Sans", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-navbar: linear-gradient(135deg, #1d58a8 0%, #039bee 100%);
  --color-bg: #040c18;
  --color-footer: linear-gradient(169.49deg, #1d58a8 0%, #039bee 171.2%);
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;

  --font-sizeP: clamp(0.75rem, 1vw, 1rem);
  --font-sizeNav: clamp(0.8rem, 1.5vw, 1.15rem);
  --front-page-headings: clamp(1.5rem, 3vw, 3rem);
  --sub-headings: clamp(0.95rem, 1.5vw, 1.5rem);
  --page-headings: clamp(1.9rem, 3.5vw, 4rem);

  --section-space: clamp(3rem, 6vw, 10rem);
}

