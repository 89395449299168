.position__navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
}

.loadmore__navbar {
  --background: rgba(0, 0, 0, 0);
  --text: white;
  --text-inverse: black;
  transition: 200ms ease-in;
  background: var(--background);
  width: 100%;
  display: flex;
  justify-content: center;
}

.loadmore__navbar:hover {
  background: rgb(29, 88, 168);
}

.testClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 130rem;
  padding-block: clamp(0.5rem, 1vw, 1rem);
}

.loadmore__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.loadmore__navbar-links_logo {
  z-index: 10;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loadmore__navbar-links_logo h1 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sub-headings);
  line-height: 30px;
  color: var(--text);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease-out;
  white-space: nowrap;
}

.loadmore__navbar-links_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuLine {
  padding-inline: 0.5rem;
  white-space: nowrap;
  padding-block: 0.5em;
}

.menuLine div {
  font-family: var(--font-navbar);
  color: var(--text);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeNav);
  line-height: 24px;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.menuLine div:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  top: 26px;
  bottom: 0;
  left: 0;
  background-color: var(--text);
  transform-origin: bottom;
  transition: transform 0.25s ease-out;
}
.menuLine div:hover:after {
  transform: scaleX(1);
  transform-origin: bottom;
}

.absoluteDrop {
  display: flex;
  justify-content: center;
  position: relative;
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 40px;
  background-color: white;
  position: absolute;
  width: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.4s ease-out;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.dropdownA {
  transform: scaleY(1);
  transform-origin: top;
}

.dropdown-link {
  display: block;
  font-family: var(--font-navbar);
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeP);

  padding: 1rem;
  cursor: pointer;
}

.dropdown-link:hover {
  background: #5cabff;
}

.nav-scrolled {
  --text: white;
  --text-inverse: white;
  --background: rgb(29, 88, 168);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.sidebarMenuIcon {
  color: var(--text);
}

.nav__sub {
  margin: 0em;
  position: absolute;
  bottom: -2px;
}

.showText h1 {
  transition-delay: 0.3s;
  transform: scaleX(1);
  transform-origin: left;
}

@media screen and (max-width: 1550px) {
  .loadmore__navbar-links_container {
    display: none;
  }
}
