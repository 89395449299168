.loadmore__accomodation-container_article {
  width: 100%;

  display: flex;
  flex-direction: column;
}

.loadmore__accomodation-container_article-image img {
  width: 100%;
  aspect-ratio: 4/3;
}

.loadmore__accomodation-container_article-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;
  height: 100%;
}

.loadmore__accomodation-container_article-content h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding-bottom: 0em;
}

.loadmore__accomodation-container_article-content h5 {
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 300;
  font-size: var(--font-sizeP);

  padding: 0.5em 0em;
}

.loadmore__accomodation-container_article-content p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  line-height: 24px;
}

.booking__container {
  padding-top: 1rem;
}

.loadmore__accomodation-weight p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-sizeP);
  line-height: 24px;
}

@media screen and (max-width: 550px) {
  .loadmore__accomodation-container_article-content {
    padding: 0.2rem 0rem;
  }
}
