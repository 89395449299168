.loadmore__footer {
  background: var(--color-footer);
  color: white;
}

.footer__maxWidth {
  margin: 0 auto;
  max-width: 130rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loadmore__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100%;
  text-align: left;
}

.loadmore__footer-heading {
  width: 65%;
}

.loadmore__footer-heading h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sub-headings);
}

.loadmore__footer-links div {
  margin-top: 1rem;
  margin-right: 2rem;
}

.loadmore__footer-links__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loadmore__footer-links__logo img {
  width: clamp(3rem, 8vw, 5rem);
  object-fit: contain;
  margin: 1rem;
}

.loadmore__footer-links__logo h1 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--front-page-headings);
}

.loadmore__footer-links__div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.loadmore__footer-links__div h4 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-sizeP);
  line-height: 22px;
}
.loadmore__footer-links__div a,
.loadmore__footer-links__div p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-sizeP);
}

.loadmore__footer-links__div a {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .footer__maxWidth {
    flex-direction: column;
  }
}
