.abstract {
  display: flex;
  justify-content: center;
  margin-block: clamp(2rem, 4vw, 7rem);
}

.abstract__section {
  position: relative;
  margin: 0 auto;
  max-width: 120rem;

  width: 100%;
}

.abstract__section h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.abstract__section h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);
  padding-block: 0.5em;
}
.abstract__section h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--sub-headings);
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.abstract__section p {
  font-family: var(--font-familyp);
  font-style: italic;
  font-weight: 400;
  font-size: var(--font-sizeP);
}

.abstract__section-text_wrap h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding-block: 0.5em;
}

.abstract__section-text_wrap ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;

  padding-left: 1rem;
}

.abstract__section ul {
  padding-left: 1.5rem;
}

.abstract__section li,
.abstract__section-text_wrap p,
.abstract__section-text_wrap li {
  font-family: var(--font-familyp);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-sizeP);
}

.abstract__section-text {
  display: flex;
  flex-direction: column;
}

.abstract__section-text_form {
  position: relative;

  padding-right: 1rem;
  margin-top: 2rem;
  width: 70%;
}

.abstract__section-text_form img {
  aspect-ratio: 1/1;
  width: 100%;
}

.abstract__section-text_wrap {
  padding-bottom: 1rem;
}
.abstract__section-text_wrap1 {
  padding-bottom: 1rem;
  margin-top: 2rem;
}

.abstract__section-text:last-child {
  flex-direction: column-reverse;
}

.abstract__fade {
  position: absolute;
  width: 100%;
  height: 40%;
  bottom: 0rem;

  background: linear-gradient(0deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
}

.abstract__buttonWrap {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  border-radius: 50px;
  padding: 11px 2px;
}

.abstract__buttonWrap-container {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 10rem;
  width: min(20rem, 80%);
}

.abstract__buttonWrap-form {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
  margin-top: 1rem;
}

.abstract__buttonWrap-form a {
  font-family: var(--font-familyp);
  font-weight: 300;
  font-size: var(--font-sizeP);
  white-space: nowrap;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.abstract__buttonWrap-form a:hover {
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  color: white;
}

@media screen and (max-width: 850px) {
  .abstract__buttonWrap-form a {
    padding: 5px 10px;
  }
  .abstract__section-text_form {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .abstract__buttonWrap-container {
    bottom: 4rem;
  }
}
