section {
  margin-block: clamp(2rem, 4vw, 7rem);
  font-family: var(--font-family);
}

.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.grid__section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 5rem;
}

.title_bio p {
  white-space: pre-wrap;
  font-size: var(--font-sizeP);
  line-height: 1.5em;
}

.speaker_image_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.img_overflow_container {
  width: min(20vw, 12rem);
  aspect-ratio: 3/4;
  overflow: hidden;
  float: left;
  margin-bottom: 1rem;
  margin-right: 1rem;
  display: grid;
  place-items: center;
}
.img_overflow_container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.title_bio_container {
  background-color: aliceblue;
  width: 100%;
  padding-inline: 1rem;
  margin-bottom: 1rem;
}
.title_bio_container h3 {
  color: rgb(50, 129, 255);
  font-size: var(--sub-headings);
}
.title_bio_container p {
  padding-inline: 0rem;
  white-space: pre-wrap;
}

.speakers__weight {
  color: rgb(50, 129, 255);
}

.open_abstract h4 {
  padding-block: 1.5em;
  font-size: var(--font-sizeNav);
}
.open_abstract p {
  font-size: var(--font-sizeP);
  line-height: 1.5em;
  white-space: pre-wrap;
}
