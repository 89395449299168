section {
  margin-block: clamp(2rem, 4vw, 7rem);
}
.assembly__container {
  margin: 0 auto;
  max-width: 120rem;
}

.assembly__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);
}

.assembly img {
  width: 100%;
}
.assembly_img1 {
  display: none;
}

@media screen and (max-width: 900px) {
  .assembly_img {
    display: none;
  }
  .assembly_img1 {
    display: flex;
  }
}
