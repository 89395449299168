section {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.spons__container {
  margin: 0 auto;
  max-width: 120rem;
}

.spons__container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 1em;
}

.spons_mini_container {
  margin: 0 auto;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spons_mini_container h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);
  padding: 1em;
  border-radius: 30px;
}

.platinum_grid_container,
.gold_grid_container,
.bronze_grid_container,
.additional_grid_container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  margin-bottom: 5rem;
  border: 0.2rem solid rgb(245, 245, 245);
  border-radius: 30px;
  place-items: center;
}
.platinum_grid_container h3,
.gold_grid_container h3,
.bronze_grid_container h3,
.additional_grid_container h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--font-sizeP);
  padding-bottom: 2em;
}
.platinum_grid_container a,
.gold_grid_container a,
.bronze_grid_container a,
.additional_grid_container a {
  cursor: pointer;
  padding: 1rem;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.platinum_grid_container,
.platinum_grid_container a {
  width: 100%;
}
.gold_grid_container,
.gold_grid_container a {
  width: 90%;
}
.bronze_grid_container,
.bronze_grid_container a {
  width: 80%;
}
.additional_grid_container,
.additional_grid_container div {
  width: 70%;
  display: grid;
  place-items: center;
}

.platinum_grid_container img,
.gold_grid_container img,
.bronze_grid_container img,
.additional_grid_container img {
  width: 100%;
}

.platinum {
  background: linear-gradient(91.24deg, #9e9eff 0%, #6466bb 100%);
  width: 100%;
}
.gold {
  background: linear-gradient(91.24deg, #ffe279 0%, #cd8b5b 100%);
  width: 90%;
}
.bronze {
  background: linear-gradient(91.24deg, #f7e1c0 0%, #b89371 100%);
  width: 80%;
}
.additional {
  background: linear-gradient(91.24deg, #b5dbfd 0%, #6bb7ed 100%);
  width: 70%;
}

.spons_bigger img {
  width: 115%;
}

@media screen and (max-width: 800px) {
  .platinum_grid_container,
  .gold_grid_container,
  .bronze_grid_container,
  .additional_grid_container {
    grid-template-columns: repeat(1, 1fr);
  }
}
