.loadmore__home {
  background: white;

  color: black;
}

.loadmore__home-container {
  position: relative;
}
.home_container_wrapper {
  overflow: hidden;
  width: 100%;
  max-height: 100vh;
  position: relative;
}

.home_container_wrapper img {
  width: 100%;
  object-fit: contain;
}

.maxWidth {
  margin: 0 auto;
  max-width: 120rem;
}

.loadmore__home-container_logo {
  position: absolute;
  bottom: -2rem;
  display: flex;
  z-index: 4;
}

.loadmore__home-container_logo img {
  width: clamp(5rem, 10vw, 10.75rem);
  object-fit: contain;
}

.loadmore__home-container_logo-text {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  color: white;
  transition: 0.5s;
}

.loadmore__home-container_logo-text a {
  text-decoration: underline;
  padding: 1em;
}

.loadmore__home-container_logo-text:hover {
  background: rgba(0, 0, 0, 0.6);
}
.loadmore__home-container_logo-text p {
  font-family: var(--font-familyp);
  font-weight: 700;
  font-size: var(--font-sizeP);
}

.loadmore__home-container_logo-text h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--front-page-headings);
}

.loadmore__home-container_logo-text h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);

  padding-bottom: 0.5em;
}

.loadmore__home-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 120rem;
}

.loadmore__home-content_first {
  display: flex;
  flex-direction: row;
}

.loadmore__home-content_second {
  padding-top: 8rem;
  display: none;
}

.loadmore__home-content_first h3,
.loadmore__home-content_second h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);

  padding-bottom: 2rem;
}

.loadmore__home-content_third-text_container h3 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--sub-headings);

  padding-left: 1rem;
  margin-left: 7rem;
}

.loadmore__home-content_first-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loadmore__home-content_second-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loadmore__home-content_first-text p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  line-height: 24px;
  padding-right: 1rem;
  padding-bottom: 1rem;
}
.loadmore__home-content_third-text_container-white p {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
  padding: 1rem;
  padding-top: 0rem;
  margin-left: 7rem;
}

.loadmore__home-content_first-text_image {
  width: 270%;
  overflow: hidden;

  border-radius: 2%;
}

.loadmore__home-content_first-text_image img {
  height: 100%;
  object-fit: contain;
}

.loadmore__home-content_second-text_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem;
  margin-left: 0rem;
  height: 100%;
  width: 100%;
}

.loadmore__home-content_second-text_container img {
  width: 100%;
  height: 100%;
}

.picture__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
}

.picture__text h6 {
  font-family: var(--font-familyp);
  font-weight: 600;
  font-size: var(--font-sizeP);
}

.picture__text p {
  font-family: var(--font-familyp);
  font-weight: 300;
  font-size: var(--font-sizeP);
}

.loadmore__home-content_third {
  display: flex;
  justify-content: space-between;

  padding-top: var(--section-space);
}

.loadmore__home-content_third-text {
  position: relative;
  width: 45%;
  height: 100%;
}

.loadmore__home-content_third-text img {
  width: 200px;
  object-fit: contain;
  position: absolute;
  margin-top: 0.65rem;
}

.loadmore__home-content_third-text_container {
  border-radius: 10px;
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  padding: clamp(0.2rem, 0.5vw, 0.4rem);
  margin-left: 4rem;
}

.loadmore__home-content_third-text_container-white {
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
}

.see-more {
  width: 30%;
  margin: 2rem;
  margin-bottom: 4.5rem;
  border-radius: 10px;
  background: linear-gradient(135deg, #1e56a6 0%, #4cd392 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.see-more-inner {
  border-radius: 6px;
  background: white;
  width: 94%;
  height: 97%;
  display: flex;
  align-items: center;
}

.see-more-inner p {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--font-sizeNav);
  line-height: 30px;
  margin-left: 1rem;
  z-index: 1;
  white-space: nowrap;
  display: none;
}

.icon-home {
  font-size: var(--front-page-headings);
  z-index: 1;
  display: none;
}

.fade-home {
  position: absolute;
  left: 40%;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
}

@media screen and (max-width: 1080px) {
  .loadmore__home-content_first {
    display: flex;
    flex-direction: column;
  }
  .loadmore__home-content_first-text_image {
    width: 100%;
    overflow: unset;
  }
  .loadmore__home-content_first-text_image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .loadmore__home-content_third-text {
    position: relative;
    width: 48%;
    height: 100%;
    padding-top: 1rem;
  }

  .loadmore__home-content_third-text img {
    width: clamp(10rem, 17.5vw, 12rem);
    margin-top: 0rem;
    left: 1.2rem;
  }

  .loadmore__home-content_third-text_container h3 {
    margin-top: clamp(4.5rem, 9vw, 10rem);
    margin-left: 0rem;
    padding-left: 0.6rem;
  }
  .loadmore__home-content_third-text_container-white p {
    margin-left: 0rem;
    padding-left: 0.6rem;
  }
  .loadmore__home-content_third-text_container {
    margin-top: 5rem;
    margin-left: 0rem;
  }
  .loadmore__home-content_third-text_container-white {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .loadmore__home-content_third {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .loadmore__home-content_third-text {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
  }
  .loadmore__home-container_logo {
    position: absolute;
    bottom: 50%;
    display: flex;
    z-index: 4;
    transform: translateY(50%);
  }
}

@media screen and (max-width: 550px) {
  .home_container_wrapper img {
    height: 100vh;
    object-fit: cover;
  }
}
