.social {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.social__section {
  margin: 0 auto;
  max-width: 120rem;
}
.social__section img {
  width: 100%;
}

.social__section h1 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--page-headings);
}

.social__section h4 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--sub-headings);
  padding-top: 3em;
  padding-bottom: 1em;
}

.social__section p {
  font-family: var(--font-familyp);

  font-weight: 400;
  font-size: var(--font-sizeP);
}
