.venue {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.venue__section {
  margin: 0 auto;
  max-width: 120rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.venue__section h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 0.5em;
}

.venue__section-container {
  display: flex;
  flex-direction: row;

  gap: 1rem;
}

.imageSize1 {
  height: max(100rem, 100%);
}
.imageSize2 {
  width: max(200rem, 190%);
}
.imageSize3 {
  width: max(100rem, 155%);
}

.imageWrap {
  overflow: hidden;
  border-radius: 2%;
}

.imageWrap img {
  object-fit: contain;
  width: 100%;
}

.venue__section-container_text {
  display: flex;
  flex-direction: column;
}

.venue__section-container_text h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--front-page-headings);

  padding-bottom: 0.8em;
}
.venue__section-container_text h3,
.venue__section-container_text h4 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--sub-headings);

  padding-bottom: 0.8em;
}
.venue__section-container_text h4 {
  padding-top: 1.5em;
}

.venue__section-container_text p,
.venue__section-container_text li {
  font-family: var(--font-familyp);
  font-weight: 400;
  font-size: var(--font-sizeP);
}

.venue__section-container_text ul {
  padding: 1rem;
}

@media screen and (max-width: 1080px) {
  .imageSize1,
  .imageSize2,
  .imageSize3 {
    width: 100%;
  }

  .imageWrap img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
  .venue__section-container {
    flex-direction: column;
  }
}
