.committee__maxWidth {
  margin-block: clamp(2rem, 4vw, 7rem);
}

.loadmore__committee {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 120rem;
}

.loadmore__committee-heading {
  width: 100%;
  text-align: left;
}

.loadmore__committee-heading h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--page-headings);

  padding-bottom: 2em;
}

.loadmore__committee-heading h3 {
  font-family: var(--font-family);

  font-weight: 700;
  font-size: var(--sub-headings);
  padding-bottom: 2em;
}

.loadmore__committee-container {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: clamp(0.5rem, 1.5vw, 3rem);

  margin-bottom: 2rem;
}

.loadmore__committee-padding {
  padding: 5rem 0rem;
}

@media screen and (max-width: 1500px) {
  .loadmore__committee-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1080px) {
  .loadmore__committee-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .loadmore__committee-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .loadmore__committee-container {
    gap: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .loadmore__committee-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .loadmore__committee-container {
    gap: 0.5rem;
  }
}
